<script setup lang="ts">
type Props = {
    value?: string;
    showError?: boolean;
    placeholder?: string;
    rows?: number;
    variant?: 'white' | 'gray';
    focus?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    focus: false,
    placeholder: '',
    rows: 2,
    showError: false,
    value: '',
    variant: 'white',
});

const textArea = ref<HTMLElement | null>(null);

const emit = defineEmits(['onChange', 'onInput']);

const emitValue = (event: Event): void => {
    if (event.target) {
        const newValue = (event.target as HTMLTextAreaElement).value;
        if (event.type === 'change') {
            emit('onChange', newValue);
        } else {
            emit('onInput', newValue);
        }
    }
};

onMounted(() => {
    if (props.focus) {
        setTimeout(() => {
            if (textArea.value) {
                textArea.value.focus();
            }
        });
    }
});
</script>

<template>
    <textarea
        ref="textArea"
        :value="value"
        class="h-full w-full resize-none p-4 tracking-[-0.1px] text-gray-900 focus:outline-none"
        :class="{
            'border-red-700': showError,
            'border-gray-300 focus-within:border-blue-900': !showError,
            'border-b bg-gray-50': variant === 'gray',
            'rounded border bg-white': variant === 'white',
        }"
        :rows="rows"
        :placeholder="placeholder"
        name="message_body"
        @input="emitValue"
        @change="emitValue"
    />
</template>
